export const smileysList = [
  '😂',
  '😄',
  '😃',
  '😀',
  '😊',
  '😉',
  '😍',
  '😘',
  '😚',
  '😗',
  '😙',
  '😜',
  '😝',
  '😛',
  '😳',
  '😁',
  '😔',
  '😌',
  '😒',
  '😞',
  '😣',
  '😢',
  '😭',
  '😪',
  '😥',
  '😰',
  '😅',
  '😓',
  '😩',
  '😫',
  '😨',
  '😱',
  '😠',
  '😡',
  '😤',
  '😖',
  '😆',
  '😋',
  '😷',
  '😎',
  '😴',
  '😵',
  '😲',
  '😟',
  '😦',
  '😧',
  '😈',
  '👿',
  '😮',
  '😬',
  '😐',
  '😕',
  '😯',
  '😶',
  '😇',
  '😏',
  '😑',
  '🙃',
  '🙄',
  '🤐',
  '🤑',
  '🤒',
  '🤓',
  '🤔',
  '🤕',
  '🙁',
  '🙂',
  '🤗',
  '🤣',
  '🤠',
  '🤥',
  '🤤',
  '🤢',
  '🤧',
  '🤡',
  '🤖',
  '🖤',
  '💛',
  '💙',
  '💜',
  '💚',
  '🧡',
  '❤️️',
  '💔',
  '💗',
  '💓',
  '💕',
  '💖',
  '💞',
  '💘',
  '💝',
  '❣️',
  '💌',
  '💋',
  '😺',
  '😸',
  '😻',
  '😽',
  '😼',
  '🙀',
  '😿',
  '😹',
  '😾',
  '🙈',
  '🙉',
  '🙊',
  '💀',
  '👽',
  '👹',
  '👺',
  '🤩',
  '🤨',
  '🥺️',
  '🤯',
  '🤪',
  '🤬',
  '🤮',
  '🤫',
  '🤭',
  '🧐',
  '🥰️',
  '🥵️',
  '🥶️',
  '🥴️',
  '🥳️',
];

export const peopleList = [
  '👶',
  '👦',
  '👧',
  '👨',
  '👩',
  '👼',
  '🤰',
  '🤳',
  '👸',
  '🤴',
  '🧔',
  '🕺',
  '💃',
  '🕴️',
  '👻',
  '🎎',
  '☃️',
  '☠️',
  '👁‍🗨',
  '🗿',
  '👲',
  '👂',
  '👀',
  '👃',
  '👅',
  '👄',
  '👁',
  '👤',
  '👥',
  '🗣',
  '💬',
  '💭',
  '🗨',
  '🗯',
  '🧒',
  '🧑',
  '🧕',
  '🤱',
  '🦵️',
  '🦶️',
  '🦷️',
  '🦴️',
  '👪',
  '👨‍👦',
  '👨‍👦‍👦',
  '👨‍👧',
  '👨‍👧‍👦',
  '👨‍👧‍👧',
  '👨‍👩‍👦',
  '👩‍👦',
  '👩‍👦‍👦',
  '👩‍👧',
  '👩‍👧‍👦',
  '👩‍👧‍👧',
  '👨‍👨‍👦',
  '👨‍👨‍👦‍👦',
  '👨‍👨‍👧',
  '👨‍👨‍👧‍👦',
  '👨‍👨‍👧‍👧',
  '👨‍👩‍👦‍👦',
  '👨‍👩‍👧',
  '👨‍👩‍👧‍👦',
  '👨‍👩‍👧‍👧',
  '👩‍👩‍👦',
  '👩‍👩‍👦‍👦',
  '👩‍👩‍👧',
  '👩‍👩‍👧‍👦',
  '👩‍👩‍👧‍👧',
  '💏',
  '👩‍❤️‍💋‍👨',
  '👨‍❤️‍💋‍👨',
  '👩‍❤️‍💋‍👩',
  '💑',
  '👩‍❤️‍👨',
  '👨‍❤️‍👨',
  '👩‍❤️‍👩',
  '🤵',
  '🤵‍♂️',
  '🤵‍♀️',
  '🧑‍🦳',
  '👨‍🦳️',
  '👩‍🦳️',
  '🧑‍🦱',
  '👨‍🦱️',
  '👩‍🦱️',
  '🧑‍🦲',
  '👨‍🦲️',
  '👩‍🦲️',
  '🧓',
  '👴',
  '👵',
  '🧑‍🦰',
  '👨‍🦰️',
  '👩‍🦰️',
  '👱',
  '👱‍♂️',
  '👱‍♀️',
  '👰',
  '👰‍♂️',
  '👰‍♀️',
  '🧑‍🎄',
  '🎅',
  '🙆',
  '🙆‍♂️',
  '🙆‍♀️',
  '🙅',
  '🙅‍♂️',
  '🙅‍♀️',
  '💁',
  '💁‍♂️',
  '💁‍♀️',
  '🙋',
  '🙋‍♂️',
  '🙋‍♀️',
  '🤷',
  '🤷‍♂️',
  '🤷‍♀️',
  '🙍',
  '🙍‍♂️',
  '🙍‍♀️',
  '💆',
  '💆‍♂️',
  '💆‍♀️',
  '💇',
  '💇‍♂️',
  '💇‍♀️',
  '🙎',
  '🙎‍♂️',
  '🙎‍♀️',
  '🤦',
  '🤦‍♂️',
  '🤦‍♀️',
  '👮',
  '👮‍♂️',
  '👮‍♀️',
  '👷',
  '👷‍♂️',
  '👷‍♀️',
  '🧏',
  '🧏‍♂️',
  '🧏‍♀️',
  '💂',
  '💂‍♂️',
  '💂‍♀️',
  '🕵',
  '🕵️‍♂️',
  '🕵️‍♀️',
  '🙇',
  '🙇‍♂️',
  '🙇‍♀️',
  '🚶',
  '🚶‍♂️',
  '🚶‍♀️',
  '🏃',
  '🏃‍♂️',
  '🏃‍♀️',
  '🧙',
  '🧙‍♂️',
  '🧙‍♀️',
  '🧚',
  '🧚‍♂️',
  '🧚‍♀️',
  '🧛',
  '🧛‍♂️',
  '🧛‍♀️',
  '🧜',
  '🧜‍♂️',
  '🧜‍♀️',
  '🧝',
  '🧝‍♂️',
  '🧝‍♀️',
  '🧞',
  '🧞‍♂️',
  '🧞‍♀️',
  '🧖',
  '🧖‍♂️',
  '🧖‍♀️',
  '👳',
  '👳‍♀️',
  '🧗',
  '🧗‍♂️',
  '🧗‍♀️',
  '🧘',
  '🧘‍♂️',
  '🧘‍♀️',
  '🧟',
  '🧟‍♂️',
  '🧟‍♀️',
  '🦸️',
  '🦸‍♂️️',
  '🦸‍♀️️',
  '🦹️',
  '🦹‍♂️️',
  '🦹‍♀️️',
  '👯‍♂️',
  '👯',
  '🚵',
  '🚵‍♂️',
  '🚵‍♀️',
  '🚴',
  '🚴‍♂️',
  '🚴‍♀️',
  '⛹',
  '⛹️‍♂️',
  '⛹️‍♀️',
  '🏊',
  '🏊‍♂️',
  '🏊‍♀️',
  '🏄',
  '🏄‍♂️',
  '🏄‍♀️',
  '🤹',
  '🤹‍♂️',
  '🤹‍♀️',
  '🏋',
  '🏋️‍♂️',
  '🏋️‍♀️',
  '🏌',
  '🏌️‍♂️',
  '🏌️‍♀️',
  '🤸',
  '🤸‍♂️',
  '🤸‍♀️',
  '🚣',
  '🚣‍♂️',
  '🚣‍♀️',
  '🤽',
  '🤽‍♂️',
  '🤽‍♀️',
  '🤾',
  '🤾‍♂️',
  '🤾‍♀️',
  '🧑‍🍼',
  '👨‍🍼',
  '👩‍🍼',
  '🧑‍🎓',
  '👨‍🎓',
  '👩‍🎓',
  '🧑‍🎤',
  '👨‍🎤',
  '👩‍🎤',
  '🧑‍🍳',
  '👨‍🍳',
  '👩‍🍳',
  '🧑‍🚒',
  '👨‍🚒',
  '👩‍🚒',
  '🧑‍🤝‍🧑',
  '👫',
  '👬',
  '👭',
  '🧑‍🦽',
  '👨‍🦽',
  '👩‍🦽',
  '👨‍🦼',
  '👩‍🦼',
  '🧑‍🦼',
  '🧑‍🎨',
  '👨‍🎨',
  '👩‍🎨',
  '🧑‍🦯',
  '👨‍🦯',
  '👩‍🦯',
  '🧑‍🚀',
  '👨‍🚀',
  '👩‍🚀',
  '🧑‍🌾',
  '👨‍🌾',
  '👩‍🌾',
  '🧑‍⚖️',
  '👨‍⚖️',
  '👩‍⚖️',
  '🧑‍🔧',
  '👨‍🔧',
  '👩‍🔧',
  '🧑‍💼',
  '👨‍💼',
  '👩‍💼',
  '🧑‍⚕️',
  '👨‍⚕️',
  '👩‍⚕️',
  '🧑‍💻',
  '👨‍💻',
  '👩‍💻',
  '🧑‍🔬',
  '👨‍🔬',
  '👩‍🔬',
  '🧑‍🏫',
  '👨‍🏫',
  '👩‍🏫',
  '🧑‍🏭',
  '👨‍🏭',
  '👩‍🏭',
  '🧑‍✈️',
  '👨‍✈️',
  '👩‍✈️',
];

export const handsList = [
  '💅',
  '👍',
  '👎',
  '👌',
  '👊',
  '✊',
  '✌️',
  '👋',
  '✋',
  '👐',
  '👆',
  '👇',
  '👉',
  '👈',
  '🙌',
  '🙏',
  '💪',
  '🖖',
  '🖐',
  '☝️',
  '👏',
  '✍️',
  '🤘',
  '🖕',
  '🤞',
  '🤙',
  '🤛',
  '🤜',
  '🤚',
  '🤝',
  '🤟',
  '🤲',
];
