import Layout from 'components/layout';
import SEO from 'components/seo';

import bannerBgSVG from 'assets/images/gallery/smaller_shutterstock_1389573218.svg';
import Icons from 'components/Tools/Icons/Icons';
import useIsClient from 'hooks/useIsClient';
import styled from 'styled-components';

const BackGround = styled.div`
  background: url(${bannerBgSVG}) no-repeat center top / cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
`;

export default function IconsPage(): JSX.Element | null {
  const {isClient} = useIsClient();

  if (!isClient) {
    return null;
  }

  return (
    <>
      <BackGround className='BackGround_BackGround' />
      <Layout>
        <>
          <SEO title='Icons grabber' />
          <Icons />
        </>
      </Layout>
    </>
  );
}
