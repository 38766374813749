import {Box, Container, Flex, Tooltip, useToast} from '@chakra-ui/react';
import WPQuote from 'components/Quote/WPQuote';
import ShareButtons from 'components/ShareButtons/ShareButtons';
import Banner from 'sections/Banner/Banner';
import {copyTextToClipboard} from 'utilities/helpers';
import {handsList, peopleList, smileysList} from './icons';
import Article from 'components/Article/Article';

export default function Icons(): JSX.Element {
  const toast = useToast();

  const handleOnClick = textToCopy => {
    copyTextToClipboard(textToCopy).then(
      function () {
        toast({
          title: 'Icon liegt in deiner Zwischenablage',
          description: textToCopy,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      },
      function (error) {
        console.error(error);
        toast({
          title: 'Deine Zwischenablage scheint nicht zu funktionieren 😰',
          description: textToCopy,
          status: 'warning',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      },
    );
  };

  const getContent = list => (
    <Container maxW='container.sm' width='100%'>
      <Box
        className='wp-block-pullquote'
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          margin: '2rem 0',
          maxWidth: '100%',
        }}
      >
        {list.map(x => {
          return (
            <span style={{padding: '5px', cursor: 'pointer'}} key={x}>
              <Tooltip
                label={x}
                fontSize='5rem'
                placement='top-start'
                bg='linear-gradient(to top, #fff, rgb(100, 171, 187))'
              >
                <span style={{fontSize: '1.5rem'}} onClick={() => handleOnClick(x)}>
                  {x}
                </span>
              </Tooltip>
            </span>
          );
        })}
      </Box>
    </Container>
  );

  return (
    <>
      {/* <Box>
        <WPQuote
          content={
            <>
              <p>Klicke auf ein Icon um es in die Zwischenablage zu kopieren ✌️</p>
              <cite>
                Ist ein total praktisches Tool das du mit deinen Kollegen teilen musst 🤝
                <br />
                <br />
                <ShareButtons />
              </cite>
            </>
          }
        />
      </Box> */}
      {getContent(smileysList)}
      {getContent(peopleList)}
      {getContent(handsList)}
      {/* <Article title='Smileys' content={getContent(smileysList)} />
      <Article title='People' content={getContent(peopleList)} />
      <Article title='Hands' content={getContent(handsList)} /> */}
    </>
  );
}
